<template>
  <div>
    <div class="text-h6 mt-3 mb-2">{{ label }}</div>
    <div v-if="!editMode" @click="handleEdit">
      <div v-if="hasContent" v-html="value" :class="readonly ? '' : 'edit-box'"></div>
      <div v-else :class="readonly ? '' : 'edit-box'">
        <em class="grey--text">{{ $t('error.no_content') }}</em>
      </div>
    </div>
    <div v-show="editMode">
      <tip-tap 
        v-model="internal"
        spellcheck="false"
        :extended="extended"
      />
      <v-btn 
        color="primary"
        class="mr-2"
        :loading="isSaving"
        @click="handleSave"
        small
      >
        {{ $t('label.save') }}
      </v-btn>
      <v-btn
        color="primary"
        text
        small
        @click="handleCancel"
      >
        {{ $t('label.cancel') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToggableTipTap',
  props: ['value', 'label', 'save', 'readonly', 'extended'],
  data() {return {
    editMode: false,
    backup: null,
    isSaving: false
  }},
  computed: {
    hasContent() {
      return !!this.value;
    },
    internal: {
      get() { return this.value; },
      set(val) { this.$emit('input', val);}
    }
  },
  watch: {
    $route() {
      this.editMode = this.isSaving = false;
    }
  },
  methods: {
    handleEdit() {
      if(this.readonly) return;
      this.backup = this.value;
      this.editMode = true;
    },
    handleCancel() {
      this.internal = this.backup;
      this.editMode = this.isSaving = false;
    },
    handleSave() {
      this.isSaving = true;
      this.$emit('save', this.value);
    
    },
    markAsSaved() {
      this.isSaving = this.editMode = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-box {
  margin: -8px;
  padding: 8px;
  border-radius: 4px;

  &:hover {
    background-color: #eee;
    cursor: pointer;
  }
}
</style>